/**
 * @module WordPress Hooks
 * @description
 */
import useSWR from 'swr';

/**
 * @function fetcher
 * @description Fetch and sort out our products and disclosures
 */
const fetcher = ( url ) => fetch( url )
  .then( ( res ) => res.json() )
  .then( ( response ) => {
    if ( ! response ) {
      return;
    }

    const { products, disclosures } = response.data;

    return {
      products,
      disclosures
    };
  } )
  .catch( ( err ) => {
    console.error( err );

    return err;
  } );

/**
 * @function init
 * @description Kick off this modules functions
 */
const useWordPress = () => {
  const options = { // https://swr.vercel.app/docs/options
    refreshInterval: 600000, // 10 mins,
    revalidateIfStale: false,
    revalidateOnFocus: false
  };

  // live
  const { data, error } = useSWR( `https://fastly-tundra.frontier.redventures.io/data/data-${ process.env.wpEnv || 'live' }.json`, fetcher, options );

  // dev
  // const { data, error } = useSWR( `https://tundra.frontier.redventures.io/data/data-${ process.env.wpEnv || 'dev' }.json`, fetcher, options );

  return {
    products: data?.products,
    disclosures: data?.disclosures,
    isLoading: ! error && ! data,
    isError: error
  };
};

export default useWordPress;
